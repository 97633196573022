import React from 'react';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export function PB_GuestListLine(props) {
  const {color = charcoal, className = '', ratio = 1} = props;

  // default dimensions
  const width = 32;
  const height = 32;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.6667 29.3334H8C6.93913 29.3334 5.92172 28.9119 5.17157 28.1618C4.42143 27.4116 4 26.3942 4 25.3334V6.66669C4 5.60582 4.42143 4.58841 5.17157 3.83826C5.92172 3.08811 6.93913 2.66669 8 2.66669H26.6667C27.0203 2.66669 27.3594 2.80716 27.6095 3.05721C27.8595 3.30726 28 3.6464 28 4.00002V28C28 28.3536 27.8595 28.6928 27.6095 28.9428C27.3594 29.1929 27.0203 29.3334 26.6667 29.3334ZM25.3333 26.6667V24H8C7.64638 24 7.30724 24.1405 7.05719 24.3905C6.80714 24.6406 6.66667 24.9797 6.66667 25.3334C6.66667 25.687 6.80714 26.0261 7.05719 26.2762C7.30724 26.5262 7.64638 26.6667 8 26.6667H25.3333ZM6.66667 21.56C7.084 21.4134 7.53333 21.3334 8 21.3334H25.3333V5.33335H8C7.64638 5.33335 7.30724 5.47383 7.05719 5.72388C6.80714 5.97393 6.66667 6.31306 6.66667 6.66669V21.56ZM16 13.3334C15.2928 13.3334 14.6145 13.0524 14.1144 12.5523C13.6143 12.0522 13.3333 11.3739 13.3333 10.6667C13.3333 9.95944 13.6143 9.28117 14.1144 8.78107C14.6145 8.28097 15.2928 8.00002 16 8.00002C16.7072 8.00002 17.3855 8.28097 17.8856 8.78107C18.3857 9.28117 18.6667 9.95944 18.6667 10.6667C18.6667 11.3739 18.3857 12.0522 17.8856 12.5523C17.3855 13.0524 16.7072 13.3334 16 13.3334ZM12 18.6667C12 17.6058 12.4214 16.5884 13.1716 15.8383C13.9217 15.0881 14.9391 14.6667 16 14.6667C17.0609 14.6667 18.0783 15.0881 18.8284 15.8383C19.5786 16.5884 20 17.6058 20 18.6667H12Z"
        fill={color}
      />
    </svg>
  );
}
