import React from 'react';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export function PB_CloseLine(props) {
  const {color = charcoal, className = '', ratio = 1} = props;

  // default dimensions
  const width = 32;
  const height = 32;

  // Use ratio to calculate width and height
  const style = {
    height: `${ratio * height}px`,
    width: `${ratio * width}px`,
    ...props.style,
  };

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.onClick}
    >
      <path
        d="M16 14.1146L22.6 7.51465L24.4853 9.39998L17.8853 16L24.4853 22.6L22.6 24.4853L16 17.8853L9.39998 24.4853L7.51465 22.6L14.1146 16L7.51465 9.39998L9.39998 7.51465L16 14.1146Z"
        fill={color}
      />
    </svg>
  );
}
