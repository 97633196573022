import React from 'react';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export function PB_EditLine(props) {
  const {color = charcoal, className = '', ratio = 1} = props;

  // default dimensions
  const width = 32;
  const height = 32;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.onClick}
      {...props}
    >
      <path
        d="M8.552 21.3334L22.0747 7.81077L20.1893 5.92543L6.66667 19.4481V21.3334H8.552ZM9.65733 24.0001H4V18.3428L19.2467 3.0961C19.4967 2.84614 19.8358 2.70572 20.1893 2.70572C20.5429 2.70572 20.882 2.84614 21.132 3.0961L24.904 6.8681C25.154 7.11814 25.2944 7.45722 25.2944 7.81077C25.2944 8.16432 25.154 8.5034 24.904 8.75343L9.65733 24.0001ZM4 26.6668H28V29.3334H4V26.6668Z"
        fill={color}
      />
    </svg>
  );
}
