// export {default as SignupModal} from './SignupModal';

import React from 'react';
import {AsyncComponent} from 'async-component';

export function SignupModal(props) {
  return (
    <AsyncComponent
      exportName="SignupModal"
      // loaderStyle={{}}
      resolve={() => import(/* webpackChunkName: "signupModal" */ '~/SignupModal/SignupModal')}
      {...props}
    />
  );
}
