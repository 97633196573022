/* eslint-disable react/require-default-props */
import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Divider} from '~/common/_pb_components/atoms/Divider';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {Button} from '~/common/_pb_components/atoms/Button';
import {PB_CloseLine as Closeline} from '~/common/svg/PB_CloseLine';
import {PB_Chevron as Chevron} from '~/common/svg/PB_Chevron';
import {MWCategoryViewAB} from '../MWCategoryView/MWCategoryViewAB';

import './CategoryDrawer.sass';

export const MWCategoryDrawerAB = ({
  open,
  closeNav,
  invitationCategories,
  greetingCardCategory,
  eGiftCardCategory,
  signupSheetCategory,
}) => {
  const [level1Category, setLevel1Category] = useState(null);
  const [level2Category, setLevel2Category] = useState(null);
  const [navLevel, setNavLevel] = useState(0);

  const allCategories = [
    ...invitationCategories,
    greetingCardCategory,
    eGiftCardCategory,
    signupSheetCategory,
  ];

  const sliderTitle = level2Category ? level2Category?.display_name : level1Category?.title;

  useEffect(() => {
    document.body.classList.toggle('no-scroll', open);
  }, [open]);

  /** Go back to the previous nav level */
  const goBack = useCallback(() => {
    setNavLevel(navLevel - 1);
    setTimeout(() => {
      if (level2Category) {
        setLevel2Category(null);
      } else if (level1Category) {
        setLevel1Category(null);
      }
    }, 300);
  }, [navLevel, level1Category, level2Category]);

  /** Close nav and reset state */
  const onCloseNav = useCallback(() => {
    closeNav();
    setTimeout(() => {
      setNavLevel(0);
      setLevel1Category(null);
      setLevel2Category(null);
    }, 300);
  }, [closeNav]);

  const renderCategoryView = (cats, level, allCategory) => {
    const categories = cats ?? allCategory?.subCategories;
    return (
      <div className={cx('mw-slide', `mw-slide-${level}`)}>
        {allCategory && (
          <MWCategoryViewAB
            key={allCategory.title || allCategory.display_name}
            category={allCategory}
            title={`All ${allCategory.title}`}
            linkTabIndex={open ? 0 : -1}
            level={2}
            setLevel1Category={setLevel1Category}
            setLevel2Category={setLevel2Category}
            setNavLevel={setNavLevel}
            allHref={allCategory.href}
          />
        )}
        {categories?.map((category) => {
          if (!category) return null;
          return (
            <MWCategoryViewAB
              key={category.title || category.display_name}
              category={category}
              title={level === 0 ? category.title : category.display_name}
              linkTabIndex={open ? 0 : -1}
              level={level}
              setLevel1Category={setLevel1Category}
              setLevel2Category={setLevel2Category}
              setNavLevel={setNavLevel}
            />
          );
        })}
      </div>
    );
  };

  return (
    <>
      <button
        className={cx('unstyled-button', 'nav-drawer-overlay', {'drawer-open': open})}
        onClick={onCloseNav}
        type="button"
        aria-label="Nav Drawer Overlay"
        data-qa-id="navDrawerOverlay"
      />
      <div className={cx('mw-category-drawer', {'drawer-open': open})}>
        <div className="mw-category-drawer__header">
          {navLevel > 0 ? (
            <Button
              variant="unstyled-button"
              onClick={goBack}
              data-qa-id="back-to-categories"
              className="mw-category-drawer__back-button"
            >
              <Chevron direction="left" ratio={0.5} />
              <Typography
                as="h3"
                variant="header3"
                data-qa-id="browseDesignsHeaderText"
                classNames="mw-category-drawer__header-text"
              >
                {sliderTitle}
              </Typography>
            </Button>
          ) : (
            <Typography
              as="h3"
              variant="header3"
              data-qa-id="browseDesignsHeaderText"
              classNames="mw-category-drawer__header-text"
            >
              Invitations
            </Typography>
          )}
          <Button variant="unstyled-button" onClick={onCloseNav} data-qa-id="close-category-drawer">
            <Closeline ratio={0.75} />
          </Button>
        </div>
        <Divider />
        <div
          className={cx('mw-slider-container', {
            [`level-${navLevel}-open`]: navLevel > 0,
          })}
        >
          {/** Main Categories */}
          {renderCategoryView(allCategories, 0)}

          {/** Sub Categories */}
          {renderCategoryView(null, 1, level1Category)}

          {/** Sub Sub Categories */}
          {renderCategoryView(level2Category?.categories, 2)}
        </div>
      </div>
    </>
  );
};

const categoryShape = PropTypes.shape({
  title: PropTypes.string,
  display_name: PropTypes.string,
  subCategories: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      display_name: PropTypes.string,
      categories: PropTypes.arrayOf(
        PropTypes.shape({
          href: PropTypes.string,
          dataQaId: PropTypes.string,
          new: PropTypes.bool,
          hideSubCategories: PropTypes.bool,
          name: PropTypes.string,
        })
      ),
    })
  ),
});

MWCategoryDrawerAB.propTypes = {
  open: PropTypes.bool.isRequired,
  closeNav: PropTypes.func.isRequired,
  invitationCategories: PropTypes.arrayOf(categoryShape).isRequired,
  greetingCardCategory: categoryShape,
  eGiftCardCategory: categoryShape,
  signupSheetCategory: categoryShape,
};
