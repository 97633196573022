import React, {useEffect, useRef} from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import {Button} from '~/common/_pb_components/atoms/Button';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {PB_CloseLine as PBCloseLine} from '~/common/svg/PB_CloseLine';

export const Overlay = ({
  children,
  open,
  onClose,
  type,
  fullPage,
  modalId,
  isModal,
  popupWidth,
  title,
  closeOnClickOutside = true,
  closeOnEscKeyPress = true,
  overlayCloseBtnDataQAId = 'overlay-close',
  overlayDataImpressionTracking = 'false',
}) => {
  let isOpen = open;
  const overlayRef = useRef(null);

  const handleClose = (e) => {
    if (onClose) {
      onClose(e);
    } else {
      isOpen = false;
    }
  };
  const escHandler = (e) => {
    if (closeOnEscKeyPress && e.key === 'Escape') {
      handleClose(e);
    }
  };

  const handleOutsideClick = (e) => {
    if (closeOnClickOutside && overlayRef.current && !overlayRef.current.contains(e.target)) {
      handleClose(e);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', escHandler);
    window.addEventListener('mousedown', handleOutsideClick);
    window.dataLayer.push({event: 'overlayModalImpression'});

    return () => {
      window.removeEventListener('keydown', escHandler);
      window.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className={cx('overlay', modalId, {open: isOpen})} data-impression-tracking="true">
      <div
        className={cx(type || 'popup', {'full-page': fullPage, 'popup-modal': isModal})}
        style={{width: popupWidth}}
        ref={overlayRef}
      >
        <div className={cx('overlay-title-row', {'overlay-no-title': !title})}>
          {title && (
            <Typography variant="list2" size="large">
              {title}
            </Typography>
          )}
          <Button
            data-qa-id={overlayCloseBtnDataQAId}
            data-impression-tracking={overlayDataImpressionTracking ? 'true' : 'false'}
            variant="transparent"
            onClick={onClose}
          >
            <PBCloseLine ratio={0.75} />
          </Button>
        </div>
        {children}
      </div>
    </div>
  );
};

Overlay.propTypes = {
  overlayDataImpressionTracking: PropTypes.string,
  open: PropTypes.bool.isRequired,
  fullPage: PropTypes.bool,
  type: PropTypes.oneOf(['popup', 'drawer']),
  children: PropTypes.node.isRequired,
  closeOnClickOutside: PropTypes.bool,
  closeOnEscKeyPress: PropTypes.bool,
  popupWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
