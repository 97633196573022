/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/no-autofocus */
import React, {useCallback, useMemo} from 'react';
import {components} from 'react-select';
import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';
import cx from 'classnames';
/* eslint-disable camelcase */
import {PB_Chevron} from '~/common/svg/PB_Chevron';
import {PB_Info} from '~/common/svg/PB_Info';
import {defaultTimes} from '~/common/constants/times';
/* eslint-enable camelcase */

import {
  coin,
  fog,
  formBorder,
  formInputBg,
  feedbackError,
  charcoal,
  placeholder,
  pebble,
} from '~sass/pb_styleguide/base/_exports.sass';

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <PB_Chevron direction="down" ratio={0.75} />
  </components.DropdownIndicator>
);

const SingleValue = ({children, ...props}) => {
  if (props.selectProps.placeholder === 'PT') {
    // if select is timezone...
    return (
      <components.SingleValue {...props}>{props.getValue()?.[0]?.value}</components.SingleValue>
    );
  }

  return <components.SingleValue {...props}>{children}</components.SingleValue>;
};

const CustomInput = ({selectProps, ...props}) => (
  <components.Input
    {...props}
    data-qa-id={selectProps['data-qa-id']}
    data-label={props.getValue()?.[0]?.label}
    data-value={props.getValue()?.[0]?.value}
    readOnly={props.readOnly || props.readonly}
    aria-readonly={props.readOnly || props.readonly}
  />
);

// eslint-disable-next-line max-lines-per-function
export const Input = React.forwardRef(
  (
    {
      'aria-label': ariaLabel,
      'data-qa-id': qaId,
      'data-qa-value': qaValue,
      times: timeList,
      isClearable = true,
      ...props
    },
    ref
  ) => {
    const times = useMemo(
      () => timeList || defaultTimes.map((t) => ({label: t, value: t})),
      [timeList]
    );

    const setTimeQAData = useCallback(
      (timeRef) => {
        if (timeRef && qaId && timeRef.inputRef) {
          /* eslint-disable no-param-reassign */
          timeRef.inputRef.dataset.qaId = qaId;
          timeRef.inputRef.dataset.qaValue = qaValue || props.value;
          /* eslint-enable no-param-reassign */
          const control = timeRef.inputRef.parentElement.parentElement;
          control.dataset.qaId = `${qaId}-wrapper`;
          control.dataset.qaValue = qaValue || props.value;
        }
      },
      [qaId, qaValue, props.value]
    );

    const disabled = !!props.disabled;

    // readonly prevents the keyboard from opening
    const renderCustomInput = useCallback(
      (params) => <CustomInput {...params} readOnly={props.readonly} />,
      [props.readonly]
    );

    /* eslint-disable no-nested-ternary */
    const popOver = props.popOverComponent;
    const Tooltip = props.tooltipComponent;
    return (
      <div className={cx('input-wrapper', props.className)}>
        {props.label && (
          <label className="label2 small input-label" htmlFor={props.id}>
            {props.label} {props.required && <span className="label3">(Required) </span>}
            {props.showOptionalLabel && <span className="label3">(Optional) </span>}
            {popOver ?? null}
            {props.tooltipContent && Tooltip && (
              <Tooltip
                placement="top"
                trigger={['hover', 'click']}
                overlay={<span>{props.tooltipContent}</span>}
                overlayClassName={props.overlayClassName}
                getTooltipContainer={(node) => node}
              >
                <span style={{position: 'relative'}}>
                  <PB_Info className="event-details__info" />
                </span>
              </Tooltip>
            )}
          </label>
        )}
        <div
          className={cx('input-box', {
            select: props.type === 'time',
            textarea: props.type === 'textarea',
            'toggle-input-box': props.type === 'toggle',
            otp: props.type === 'otp',
            translucent: props.translucent,
          })}
        >
          {props.startAdornment && <div className="start-adornment">{props.startAdornment}</div>}
          {['time', 'select'].includes(props.type) ? (
            <CreatableSelect
              isClearable={isClearable}
              options={props.type === 'time' ? times : props.options}
              className={props.inputClassName}
              getOptionLabel={(option) =>
                option.label === props.activeOption ? <b>{option.label}</b> : option.label
              }
              styles={{
                container: (provided) => ({
                  ...provided,
                  width: '100%',
                  height: 48,
                }),
                control: (provided, {isDisabled}) => ({
                  ...provided,
                  color: isDisabled ? coin : charcoal,
                  height: '100%',
                  paddingLeft: '0.5rem',
                  paddingRight: '0.5rem',
                  fontFamily: 'Brut',
                  fontWeight: 400,
                  borderColor: props.error ? feedbackError : formBorder,
                  borderWidth: props.error ? 2 : 'thin',
                  fontSize: '1rem',
                  lineHeight: '1rem',
                  boxShadow: undefined,
                  backgroundColor: isDisabled ? fog : formInputBg,
                  cursor: 'pointer',
                }),
                placeholder: (provided) => ({
                  ...provided,
                  color: placeholder,
                }),
                indicatorSeparator: () => ({}),
                option: (provided, state) => ({
                  ...provided,
                  fontFamily: 'Brut',
                  fontWeight: 400,
                  padding: '1rem',
                  color: props.activeOption ? pebble : charcoal,
                  backgroundColor: state.isFocused ? fog : null,
                }),
              }}
              value={
                props.type === 'time'
                  ? times.find(({value}) => value === props.value)
                  : props.options.find((opt) => opt.value === props.value)
              }
              placeholder={props.placeholder}
              components={{
                DropdownIndicator: props.hideSelectArrow ? () => null : DropdownIndicator,
                SingleValue,
                Input: renderCustomInput, // readonly prevents the keyboard from opening
              }}
              onBlur={props.onBlur}
              onChange={(newValue) => props.onChange(newValue)}
              ref={setTimeQAData}
              menuPlacement="auto"
              isDisabled={disabled}
              isSearchable={props.isSearchable}
              isValidNewOption={props.isValidNewOption}
              data-qa-id={qaId}
              data-qa-value={qaValue}
              autoFocus={props.autoFocus}
              captureMenuScroll
              blurInputOnSelect
              openMenuOnFocus={props.openMenuOnFocus}
              menuShouldScrollIntoView
              {...(props.getOptionLabel ? {getOptionLabel: props.getOptionLabel} : {})}
            />
          ) : props.type === 'toggle' ? (
            // eslint-disable-next-line jsx-a11y/label-has-associated-control
            <label className="input-toggle-container">
              <input
                type="checkbox"
                checked={props.checked}
                onChange={props.onChange}
                id={props.id}
                disabled={disabled}
                readOnly={props.readonly}
              />
              <span
                className={cx('input-toggle', {disabled})}
                data-checked={props.checked}
                data-qa-id={qaId}
                data-qa-value={qaValue}
              />
            </label>
          ) : props.type === 'textarea' ? (
            <textarea
              className={cx('input-text', 'textarea', {
                filled: props.value,
                error: props.error,
                'with-end-adornment': props.endAdornment,
                'with-start-adornment': props.startAdornment,
                translucent: props.translucent,
                'title-style': props.titleStyle,
                [props.inputClassName]: !!props.inputClassName,
              })}
              id={props.id}
              data-qa-id={qaId}
              data-qa-value={qaValue}
              value={props.value}
              onChange={props.onChange}
              onFocus={props.onFocus}
              onBlur={props.onBlur}
              onKeyDown={props.onKeyDown}
              onInput={props.onInput}
              name={props.name}
              placeholder={props.placeholder}
              ref={ref}
              disabled={disabled}
              maxLength={props.maxLength}
              autoComplete={props.autoComplete}
              pattern={props.pattern}
              autoFocus={props.autoFocus}
              rows={props.rows}
              readOnly={props.readonly}
              wrap={props.wrap}
              cols={props.cols}
            />
          ) : props.type === 'number' ? (
            <input
              type="number"
              className={cx({
                'input-counter': !props.counter,
                'input-text': props.counter,
                filled: props.value,
                error: !props.counter && props.error,
                'with-end-adornment': props.endAdornment,
                'with-start-adornment': props.startAdornment,
                translucent: props.translucent,
                'title-style': props.titleStyle,
                [props.inputClassName]: !!props.inputClassName,
              })}
              id={props.id}
              data-qa-id={qaId}
              data-qa-value={qaValue}
              value={props.value}
              onChange={props.onChange}
              onFocus={props.onFocus}
              onBlur={props.onBlur}
              onKeyDown={props.onKeyDown}
              onInput={props.onInput}
              name={props.name}
              placeholder={props.placeholder}
              ref={ref}
              disabled={disabled}
              maxLength={props.maxLength}
              autoComplete={props.autoComplete}
              pattern={props.pattern}
              autoFocus={props.autoFocus}
              onKeyPress={props.onKeyPress}
              readOnly={props.readonly}
              aria-label={ariaLabel}
              min={props.min}
              max={props.max}
            />
          ) : (
            <input
              className={cx('input-text', {
                filled: props.value,
                error: props.error,
                'with-end-adornment': props.endAdornment,
                'with-start-adornment': props.startAdornment,
                translucent: props.translucent,
                'title-style': props.titleStyle,
                [props.inputClassName]: !!props.inputClassName,
              })}
              id={props.id}
              data-qa-id={qaId}
              data-qa-value={qaValue}
              value={props.value}
              onChange={props.onChange}
              style={props.style}
              onFocus={props.onFocus}
              onBlur={props.onBlur}
              onInput={props.onInput}
              type={props.type === 'time' ? 'text' : props.type || 'text'}
              name={props.name}
              placeholder={props.placeholder}
              ref={ref}
              disabled={disabled}
              maxLength={props.maxLength}
              autoComplete={props.autoComplete}
              pattern={props.pattern}
              autoFocus={props.autoFocus}
              onKeyPress={props.onKeyPress}
              onKeyDown={props.onKeyDown}
              onPaste={props.onPaste}
              readOnly={props.readonly}
              aria-label={ariaLabel}
            />
          )}
          {props.endAdornment && <div className="end-adornment">{props.endAdornment}</div>}
        </div>
        {props.error && typeof props.error === 'string' && (
          <span className="input-error" data-qa-id={`${qaId || 'input-error'}_inputErrorMessage`}>
            {props.error}
          </span>
        )}
        {props.success && typeof props.success === 'string' && (
          <span
            className="input-success"
            data-qa-id={`${qaId || 'input-success'}_inputSuccessMessage`}
          >
            {props.success}
          </span>
        )}
      </div>
    );
    /* eslint-enable no-nested-ternary */
  }
);

Input.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  checked: PropTypes.bool,
  'data-qa-id': PropTypes.string.isRequired,
  'data-qa-value': PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.number]),
  disabled: PropTypes.bool,
  endAdornment: PropTypes.element,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  getValue: PropTypes.func,
  id: PropTypes.string,
  isSearchable: PropTypes.bool,
  isValidNewOption: PropTypes.func,
  label: PropTypes.string,
  showOptionalLabel: PropTypes.bool,
  name: PropTypes.string,
  max: PropTypes.number, // max number allowed as value
  maxLength: PropTypes.number, // max length of characters allowed
  min: PropTypes.number, // min number allowed as value
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyPress: PropTypes.func,
  onKeyDown: PropTypes.func,
  onInput: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  readonly: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  selectProps: PropTypes.object,
  startAdornment: PropTypes.element,
  success: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  // eslint-disable-next-line react/forbid-prop-types
  times: PropTypes.arrayOf(PropTypes.object, PropTypes.string),
  tooltipComponent: PropTypes.func,
  tooltipContent: PropTypes.string,
  type: PropTypes.oneOf([
    'tel',
    'text',
    'toggle',
    'email',
    'password',
    'number',
    'time',
    'select',
    'textarea',
    'otp',
  ]),
  translucent: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  titleStyle: PropTypes.bool,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  hideSelectArrow: PropTypes.bool,
  getOptionLabel: PropTypes.func,
  openMenuOnFocus: PropTypes.bool,
};

Input.defaultProps = {
  autoComplete: undefined,
  autoFocus: false,
  checked: false,
  disabled: false,
  endAdornment: null,
  error: undefined,
  getValue: undefined,
  id: undefined,
  isSearchable: false,
  isValidNewOption: undefined,
  label: undefined,
  showOptionalLabel: false,
  name: undefined,
  max: undefined,
  maxLength: undefined,
  min: undefined,
  onBlur: undefined,
  onChange: undefined,
  onFocus: undefined,
  onKeyPress: undefined,
  onKeyDown: undefined,
  onInput: undefined,
  placeholder: undefined,
  required: false,
  readOnly: false,
  readonly: false,
  selectProps: undefined,
  startAdornment: null,
  success: false,
  times: null,
  type: 'text',
  tooltipComponent: null,
  tooltipContent: null,
  translucent: false,
  value: undefined,
  titleStyle: false,
  className: '',
  inputClassName: '',
  'data-qa-value': undefined,
  hideSelectArrow: false,
  getOptionLabel: null,
  openMenuOnFocus: false,
};
