import {useState, useEffect, forwardRef} from 'react';
import PropTypes from 'prop-types';
import {tooltipColor, tooltipTextColor} from '~sass/pb_styleguide/base/_exports.sass';

import {useBrowserWidth} from '~/common/hooks/useBrowserWidth';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {PB_CloseLine} from '~/common/svg/PB_CloseLine';

export const Tooltip = forwardRef(
  (
    {
      children,
      id,
      bkgColor = tooltipColor,
      textColor = tooltipTextColor,
      position = 'bottom',
      style,
      showOnce = true,
      ...props
    },
    ref
  ) => {
    const browserWidth = useBrowserWidth();

    const [parentRect, setParentRect] = useState({
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    });
    const [show, setShow] = useState(false);

    useEffect(() => {
      if (showOnce) {
        /** Get show from local storage if available and set it in state */
        const showLocal = window.localStorage.getItem(`show_tooltip_${id}`);
        if (showLocal !== null) setShow(JSON.parse(showLocal));
        else {
          window.localStorage.setItem(`show_tooltip_${id}`, 'true');
          setShow(true);
        }
      } else {
        setShow(true);
      }

      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    // On browser resize, recalculate tooltip position
    useEffect(() => {
      setParentRect(document.querySelector(`[data-for="${id}"]`).getBoundingClientRect());
    }, [browserWidth]);

    // On scroll, recalculate tooltip position
    const handleScroll = () => {
      setParentRect(document.querySelector(`[data-for="${id}"]`).getBoundingClientRect());
    };

    /** Set show in local storage upon closing the tooltip */
    const onDismiss = () => {
      if (showOnce) window.localStorage.setItem(`show_tooltip_${id}`, 'false');
      window.dispatchEvent(new Event('tooltipClose'));
      setShow(false);
    };

    return (
      <div
        ref={ref}
        className="react_tooltip-container"
        style={{
          top: parentRect.top,
          left: parentRect.left,
          // width: parentRect.width,
          height: parentRect.height,
          ...style,
        }}
        data-qa-id={`${props['data-qa-id']}_container`}
      >
        {show && (
          <div
            className={`react_tooltip react_tooltip-${position}`}
            style={{backgroundColor: bkgColor, color: textColor}}
            data-qa-id={props['data-qa-id']}
          >
            <div className="react_tooltip-arrow" style={{backgroundColor: bkgColor}} />
            <Typography
              as="p"
              variant="paragraph2"
              style={{color: textColor}}
              classNames="react_tooltip-text"
              data-qa-id={`${props['data-qa-id']}_text`}
            >
              {children}
            </Typography>
            <button className="unstyled-button" onClick={onDismiss}>
              <PB_CloseLine className="react_tooltip-close" color={textColor} ratio={0.5} />
            </button>
          </div>
        )}
      </div>
    );
  }
);

Tooltip.propTypes = {
  position: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  id: PropTypes.string.isRequired,
  bkgColor: PropTypes.string,
  textColor: PropTypes.string,
  'data-qa-id': PropTypes.string.isRequired,
};
