import React from 'react';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export function PB_LockLine(props) {
  const {color = charcoal, className = '', ratio = 1} = props;

  // default dimensions
  const width = 32;
  const height = 32;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 10.6667V9.33334C8 7.21161 8.84285 5.17678 10.3431 3.67649C11.8434 2.1762 13.8783 1.33334 16 1.33334C18.1217 1.33334 20.1566 2.1762 21.6569 3.67649C23.1571 5.17678 24 7.21161 24 9.33334V10.6667H26.6667C27.0203 10.6667 27.3594 10.8072 27.6095 11.0572C27.8595 11.3073 28 11.6464 28 12V28C28 28.3536 27.8595 28.6928 27.6095 28.9428C27.3594 29.1929 27.0203 29.3333 26.6667 29.3333H5.33333C4.97971 29.3333 4.64057 29.1929 4.39052 28.9428C4.14048 28.6928 4 28.3536 4 28V12C4 11.6464 4.14048 11.3073 4.39052 11.0572C4.64057 10.8072 4.97971 10.6667 5.33333 10.6667H8ZM25.3333 13.3333H6.66667V26.6667H25.3333V13.3333ZM10.6667 10.6667H21.3333V9.33334C21.3333 7.91886 20.7714 6.5623 19.7712 5.56211C18.771 4.56191 17.4145 4.00001 16 4.00001C14.5855 4.00001 13.229 4.56191 12.2288 5.56211C11.2286 6.5623 10.6667 7.91886 10.6667 9.33334V10.6667Z"
        fill={color}
      />
    </svg>
  );
}
