import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import parseHTML from 'html-react-parser';
import DOMPurify from 'dompurify';
import XDate from 'xdate';

import './NotificationsView.sass';
import {Divider} from '~/common/_pb_components/atoms/Divider';
import {Link} from '~/common/_pb_components/atoms/Link';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {PB_Chevron} from '~/common/svg/PB_Chevron';

const createdAsDate = (created) => {
  const CREATED_REGEX = /^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})\.(\d{3})/;
  const match = CREATED_REGEX.exec(created);
  const d = new XDate();
  if (match) {
    d.setUTCFullYear(match[1], parseInt(match[2], 10) - 1, match[3]);
    d.setUTCHours(match[4], match[5], match[6], match[7]);
  }
  return d;
};

function Notification(props) {
  const [optionsMenuVisible, setOptionsMenuVisible] = useState(false);
  const [chevronHovered, setChevronHovered] = useState(false);
  const showOptions = () => setOptionsMenuVisible(true);
  const hideOptions = () => setOptionsMenuVisible(false);
  const showOptionsPopup = () => setChevronHovered(true);
  const hideOptionsPopup = () => setChevronHovered(false);

  useEffect(() => {
    const notification = document.getElementById(props.created);
    const optionsChevron = document.getElementById(`optionsChevron_${props.created}`);
    if (notification) {
      notification.addEventListener('mouseenter', showOptions);
      notification.addEventListener('mouseleave', hideOptions);
      optionsChevron.addEventListener('mouseenter', showOptionsPopup);
      optionsChevron.addEventListener('mouseleave', hideOptionsPopup);
      return () => {
        notification.removeEventListener('mouseenter', showOptions);
        notification.removeEventListener('mouseleave', hideOptions);
        optionsChevron.removeEventListener('mouseenter', showOptionsPopup);
        optionsChevron.removeEventListener('mouseleave', hideOptionsPopup);
      };
    }
  }, []);

  return (
    <a
      className={`pb-notification ${!props.read ? 'unread' : ''}`}
      href={props.view_details_url}
      id={props.id}
      data-qa-id={`notification_${props.id}`}
    >
      <div className="nav-avatar">
        {props.re_user_image_url && props.re_user_image_url !== 'None' ? (
          <img src={props.re_user_image_url} alt="Sender profile picture" />
        ) : (
          <Typography
            as="p"
            variant="paragraph4"
            data-qa-id={`notificationSenderInitials_${props.id}`}
          >
            {props.re_user_initials}
          </Typography>
        )}
      </div>
      <div className="notification-details">
        <Typography as="p" variant="paragraph3" data-qa-id={`notificationDetails_${props.id}`}>
          {parseHTML(DOMPurify.sanitize(props.text))}
        </Typography>
        <Typography as="p" variant="paragraph4" data-qa-id={`notificationCreatedDate_${props.id}`}>
          {createdAsDate(props.created).toString('MMMM d, yyyy')}
        </Typography>
      </div>
      <div className="option-elements-wrapper">
        <div id={`optionsChevron_${props.created}`}>
          <button
            className={`unstyled-button options-chevron ${
              optionsMenuVisible ? 'visible' : 'hidden'
            }`}
            data-qa-id={`notificationOptions_${props.id}`}
          >
            <PB_Chevron ratio={0.5} color="#92959B" />
          </button>
          {chevronHovered && (
            <button
              className="unstyled-button hide-notification-popup"
              onClick={(e) => props.onHideNotification(e, props.created)}
              data-qa-id={`hideNotificationButton_${props.id}`}
            >
              <p className="link small">Hide this notification</p>
            </button>
          )}
        </div>
        <button
          className={`unstyled-button ${optionsMenuVisible ? 'visible' : 'hidden'}`}
          onClick={(e) => {
            if (props.read) {
              props.onMarkAsUnread(e, props.created);
            } else {
              props.onMarkAsRead(e, props.created);
            }
          }}
          data-qa-id={`notificationMarkAs${props.read ? 'Unread' : 'Read'}Button_${props.id}`}
        >
          <div className={`notification-dot ${props.read ? 'read' : 'unread'}`} />
        </button>
      </div>
    </a>
  );
}

export function NotificationsView(props) {
  return (
    <div className="notifications-view">
      <div className="notifications-view__header">
        <p className="paragraph3 notifications-view__title">Your Evite notifications</p>
        <button
          className="unstyled-button paragraph3 link"
          onClick={props.onMarkAllAsRead}
          data-qa-id="notificationsView_markAllAsReadButton"
        >
          Mark all as read
        </button>
      </div>
      <Divider marginBottom="-0.5rem" />
      <div className="notifications-view__notifications">
        {props.notifications && props.notifications.length > 0 ? (
          props.notifications.map((n, i) => (
            <React.Fragment key={`notification_${i + 1}`}>
              <Notification
                {...n}
                id={n.created}
                onMarkAsUnread={props.onMarkOneAsUnread}
                onMarkAsRead={props.onMarkOneAsRead}
                onHideNotification={props.onHideNotification}
              />
              {i < props.notifications.length - 1 && (
                <Divider marginTop="-0.5rem" marginBottom="-0.5rem" />
              )}
            </React.Fragment>
          ))
        ) : (
          <div className="notifications-view__empty">
            <p className="paragraph2">
              Find all your notifications here and stay up to date on your Evite events!
            </p>
          </div>
        )}
      </div>
      <Divider marginTop="-0.5rem" />
      <div className="notifications-view__view-all-link-wrapper">
        <Link href="/all-notifications" variant="large" data-qa-id="viewAllNotificationsLink">
          View all notifications
        </Link>
      </div>
    </div>
  );
}

NotificationsView.propTypes = {
  data_layer: PropTypes.object,
  news_item: PropTypes.any,
  notifications: PropTypes.array.isRequired,
  unread: PropTypes.number.isRequired,
  unviewed: PropTypes.number.isRequired,
  onMarkAllAsRead: PropTypes.func,
  onMarkOneAsUnread: PropTypes.func,
  onMarkOneAsRead: PropTypes.func,
  onHideNotification: PropTypes.func,
};
