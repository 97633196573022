import React from 'react';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export function PB_AccountLine(props) {
  const {color = charcoal, className = '', ratio = 1} = props;

  // default dimensions
  const width = 32;
  const height = 32;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 29.3333C8.63596 29.3333 2.66663 23.364 2.66663 16C2.66663 8.636 8.63596 2.66667 16 2.66667C23.364 2.66667 29.3333 8.636 29.3333 16C29.3333 23.364 23.364 29.3333 16 29.3333ZM9.35063 24.3413C11.238 25.8503 13.5835 26.6705 16 26.6667C18.6266 26.6667 21.0306 25.7173 22.8893 24.144C22.0209 23.253 20.9827 22.5452 19.836 22.0624C18.6893 21.5796 17.4575 21.3317 16.2133 21.3333C14.9234 21.3319 13.6473 21.5985 12.4659 22.1163C11.2845 22.6341 10.2237 23.3918 9.35063 24.3413ZM7.48796 22.4267C8.60856 21.2374 9.96085 20.2903 11.4615 19.6436C12.9621 18.997 14.5793 18.6645 16.2133 18.6667C17.7888 18.6646 19.3492 18.9738 20.805 19.5765C22.2607 20.1792 23.583 21.0635 24.696 22.1787C25.8374 20.5722 26.5115 18.6809 26.6434 16.7146C26.7753 14.7483 26.3599 12.7839 25.4433 11.0393C24.5267 9.2947 23.1447 7.83817 21.4506 6.83126C19.7565 5.82436 17.8166 5.30646 15.8461 5.33501C13.8756 5.36356 11.9515 5.93745 10.2873 6.99301C8.62313 8.04858 7.2839 9.54454 6.41821 11.3149C5.55252 13.0853 5.19422 15.061 5.38305 17.0226C5.57188 18.9843 6.30046 20.8552 7.48796 22.428V22.4267ZM16 17.3333C14.5855 17.3333 13.2289 16.7714 12.2287 15.7712C11.2285 14.771 10.6666 13.4145 10.6666 12C10.6666 10.5855 11.2285 9.22896 12.2287 8.22877C13.2289 7.22857 14.5855 6.66667 16 6.66667C17.4144 6.66667 18.771 7.22857 19.7712 8.22877C20.7714 9.22896 21.3333 10.5855 21.3333 12C21.3333 13.4145 20.7714 14.771 19.7712 15.7712C18.771 16.7714 17.4144 17.3333 16 17.3333ZM16 14.6667C16.7072 14.6667 17.3855 14.3857 17.8856 13.8856C18.3857 13.3855 18.6666 12.7072 18.6666 12C18.6666 11.2928 18.3857 10.6145 17.8856 10.1144C17.3855 9.61429 16.7072 9.33334 16 9.33334C15.2927 9.33334 14.6144 9.61429 14.1143 10.1144C13.6142 10.6145 13.3333 11.2928 13.3333 12C13.3333 12.7072 13.6142 13.3855 14.1143 13.8856C14.6144 14.3857 15.2927 14.6667 16 14.6667Z"
        fill={color}
      />
    </svg>
  );
}
