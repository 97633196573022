import React from 'react';

export function PB_NotificationFill(props) {
  const {color = '#101B21', className = '', ratio = 1, badgeColor = '#FF9548'} = props;

  // default dimensions
  const width = 32;
  const height = 32;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9985 3.04567C18.0401 2.79825 17.0353 2.66666 16 2.66666C9.37333 2.66666 4 8.05733 4 14.708V26.6667H28V14.708C28 14.21 27.9699 13.7191 27.9114 13.237C27.5057 13.3004 27.09 13.3333 26.6667 13.3333C22.2484 13.3333 18.6667 9.75153 18.6667 5.33325C18.6667 4.53831 18.7826 3.77046 18.9985 3.04567ZM21.5005 4.0034C21.3914 4.42851 21.3333 4.8741 21.3333 5.33325C21.3333 8.27877 23.7211 10.6666 26.6667 10.6666C26.879 10.6666 27.0885 10.6542 27.2943 10.63C26.2638 7.7567 24.1752 5.39014 21.5005 4.0034ZM18.357 30.357C18.9821 29.7319 19.3333 28.8841 19.3333 28H12.6667C12.6667 28.8841 13.0179 29.7319 13.643 30.357C14.2681 30.9821 15.1159 31.3333 16 31.3333C16.8841 31.3333 17.7319 30.9821 18.357 30.357Z"
        fill={color}
      />
      <path
        d="M32 5.33333C32 8.27885 29.6122 10.6667 26.6666 10.6667C23.7211 10.6667 21.3333 8.27885 21.3333 5.33333C21.3333 2.38781 23.7211 0 26.6666 0C29.6122 0 32 2.38781 32 5.33333Z"
        fill={badgeColor}
      />
    </svg>
  );
}
