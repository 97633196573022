import React from 'react';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export function PB_Plus(props) {
  const {color = charcoal, className = '', ratio = 1} = props;

  // default dimensions
  const width = 32;
  const height = 32;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6667 14.6667V6.66666H17.3334V14.6667H25.3334V17.3333H17.3334V25.3333H14.6667V17.3333H6.66669V14.6667H14.6667Z"
        fill={color}
      />
    </svg>
  );
}
