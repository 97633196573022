import {useState} from 'react';
import {SignupModal} from '~/SignupModal';
import {LoginContainer} from './LoginContainer';
import {useMatchQuery} from '~/common/hooks/useMatchQuery';
import {ResetPasswordModal} from '~/common/_pb_components/organisms/ResetPasswordModal';
import {googlePlusConnect, appleConnect} from './socialLogin';

export function LoginWrapper({onSignupSuccess, onLoginSuccess, onCloser, isModal = true}) {
  const trackUserId = (userId, subscription_plan_name, email) => {
    window.dataLayer.push({user_identifier: userId});
    window.dataLayer.push({is_logged_in: 1});
    if (subscription_plan_name) window.dataLayer.push({subscription_plan_name});
    window.dataLayer.push({email});
  };

  const [forgotPasswordMode, setForgotPasswordMode] = useState(false);
  const [signupOrLogin, setSignupOrLogin] = useState('login');

  const [passwordResetOpen, setPasswordResetOpen] = useState(false);
  const handlePwReset = () => {
    setPasswordResetOpen(true);
  };
  const handlePwResetClose = () => {
    setPasswordResetOpen(false);
  };

  const {matches: lgWindow} = useMatchQuery('(min-width: 80rem)', []);

  const switchToLogin = () => setSignupOrLogin('login');
  const switchToSignup = () => setSignupOrLogin('signup');

  const onCloseHandler = () => {
    onCloser();
    setTimeout(() => {
      setForgotPasswordMode(false);
      setSignupOrLogin('login');
    }, 600);
  };

  const onForgotPasswordClick = (e) => {
    e.preventDefault();
    setForgotPasswordMode(true);
  };

  const onForgotPasswordCancel = (e) => {
    e.preventDefault();
    setForgotPasswordMode(false);
  };

  const onSuccess = (user) => {
    const successFunc = signupOrLogin === 'login' ? onLoginSuccess : onSignupSuccess;
    successFunc(
      user.user_id,
      user.full_name,
      user.subscription_plan_name,
      user.email,
      user.avatar_disk,
      user.image_url,
      user.initials,
      user.first_name,
      user.token
    );
    trackUserId(user.user_id, user.subscription_plan_name, user.email);
  };

  const onGoogleConnect = async (e) => {
    e.preventDefault();
    try {
      const user = await googlePlusConnect();
      onSuccess(user);
    } catch (error) {
      console.log('Google connect closed without logging in');
    }
  };

  const onAppleConnect = async (e) => {
    e.preventDefault();
    try {
      const user = await appleConnect();
      onSuccess(user);
    } catch (error) {
      console.log('Apple connect closed without logging in');
    }
  };

  return signupOrLogin === 'signup' ? (
    <SignupModal
      isModal={isModal}
      lgWindow={lgWindow}
      onSignInClick={switchToLogin}
      onCloseModal={onCloseHandler}
      privacyPolicyHref="/content/privacy_policy/"
      termsHref="/content/terms/"
      onSuccess={onSignupSuccess}
      onGoogleLoginClick={onGoogleConnect}
      onAppleLoginClick={onAppleConnect}
    />
  ) : (
    <>
      <LoginContainer
        isModal={isModal}
        lgWindow={lgWindow}
        onCreateAccountClick={switchToSignup}
        onCloseModal={onCloseHandler}
        onSuccess={onLoginSuccess}
        onGoogleLoginClick={onGoogleConnect}
        onAppleLoginClick={onAppleConnect}
        onForgotPasswordClick={onForgotPasswordClick}
        onForgotPasswordCancel={onForgotPasswordCancel}
        forgotPasswordMode={forgotPasswordMode}
        handlePwReset={handlePwReset}
      />
      {passwordResetOpen && (
        <ResetPasswordModal
          isOpen={passwordResetOpen}
          setIsOpen={setPasswordResetOpen}
          handleClose={handlePwResetClose}
        />
      )}
    </>
  );
}
