import {forwardRef, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

const DEFAULT_VIDEO_OPTIONS = {
  autoPlay: true,
  loop: true,
  muted: true,
  playsInline: true,
};

export const Video = forwardRef((props, videoRef) => {
  const srcArray = Array.isArray(props.src) ? props.src : [props.src];
  const options = {...DEFAULT_VIDEO_OPTIONS, ...props.options};

  if (!videoRef) videoRef = useRef(null);

  useEffect(() => {
    if (videoRef?.current && props.reload) {
      videoRef.current.load();
    }
  }, [srcArray]);

  useEffect(() => {
    if (videoRef?.current) videoRef.current.defaultMuted = options.muted;
  }, []);

  return (
    <video
      preload="auto"
      ref={videoRef}
      className={props.className}
      {...options}
      onClick={props.onClick}
      onCanPlay={props.onCanPlay}
      style={props.style}
    >
      {srcArray.map((src) => (
        <source key={src} src={src} type={`video/${src.substring(src.lastIndexOf('.') + 1)}`} />
      ))}
      Your browser does not support the video tag.
    </video>
  );
});

Video.propTypes = {
  src: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]).isRequired,
  className: PropTypes.string,
  options: PropTypes.object,
  style: PropTypes.object,
  reload: PropTypes.bool,
  onClick: PropTypes.func,
  onCanPlay: PropTypes.func,
};

Video.defaultProps = {
  className: '',
  options: DEFAULT_VIDEO_OPTIONS,
  style: null,
  reload: false,
  onClick: () => {},
  onCanPlay: () => {},
};
