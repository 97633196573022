import React from 'react';
import PropTypes from 'prop-types';

export function Picture(props) {
  const srcSetArray = Array.isArray(props.srcSet) ? props.srcSet : [props.srcSet];
  return (
    <picture id={props.id} className={props.className ? `picture__${props.className}` : null}>
      {srcSetArray.slice(0, -1).map((srcSet) => (
        <source
          key={srcSet}
          srcSet={srcSet}
          type={`image/${srcSet.substring(srcSet.lastIndexOf('.') + 1)}`}
        />
      ))}
      <img
        src={srcSetArray[srcSetArray.length - 1]}
        className={props.className || null}
        alt={props.alt || null}
        loading={props.loading}
        data-qa-id={props['data-qa-id'] || null}
      />
    </picture>
  );
}

Picture.propTypes = {
  srcSet: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]).isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  'data-qa-id': PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  alt: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  loading: PropTypes.oneOf(['eager', 'lazy']),
};

Picture.defaultProps = {
  className: null,
  alt: null,
  'data-qa-id': null,
  loading: 'eager',
};
