import PropTypes from 'prop-types';
import {Typography} from '~/common/_pb_components/atoms/Typography';

export function UserCallout(props) {
  const parser = new DOMParser();
  return (
    <Typography
      as="p"
      variant={props.variant}
      classNames={props.className}
      data-qa-id={props.dataQaId}
      color="charcoal"
    >
      Hi,{' '}
      {
        parser.parseFromString(`<!doctype html><body>${props.name}</body>`, 'text/html').body
          .textContent
      }
      !
    </Typography>
  );
}

UserCallout.propTypes = {
  name: PropTypes.string.isRequired,
  variant: PropTypes.string,
  className: PropTypes.string,
  dataQaId: PropTypes.string,
};

UserCallout.defaultProps = {
  variant: 'paragraph2',
};
