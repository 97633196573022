/* public-path Sets the cdn path of the chunks.  Must come first in this file */
import '~/common/public-path';
import React from 'react';
import * as ReactDOM from 'react-dom';
import {MegaNav} from './MegaNav';

const root = document.getElementById('meganav');

evite.when(['react', 'site-libraries']).then(() => {
  if (root) {
    ReactDOM.render(<MegaNav />, root);
  } else {
    console.warn('This page DOM is missing element with id="meganav"');
  }
});
