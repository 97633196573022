import React, {useState} from 'react';
import PropTypes from 'prop-types';

import './AccountDetailsDrawer.sass';
import {PB_AccountLine} from '~/common/svg/PB_AccountLine';
import {PB_ActivityFeedLine} from '~/common/svg/PB_ActivityFeedLine';
import {PB_AlertLine} from '~/common/svg/PB_AlertLine';
import {PB_Arrow} from '~/common/svg/PB_Arrow';
import {PB_CalendarLine} from '~/common/svg/PB_CalendarLine';
import {PB_ClickOutLine} from '~/common/svg/PB_ClickOutLine';
import {PB_CloseLine} from '~/common/svg/PB_CloseLine';
import {PB_EditLine} from '~/common/svg/PB_EditLine';
import {PB_FavoritesLine} from '~/common/svg/PB_FavoritesLine';
import {PB_GuestListLine} from '~/common/svg/PB_GuestListLine';
import {PB_LockLine} from '~/common/svg/PB_LockLine';
import {PB_SettingsLine} from '~/common/svg/PB_SettingsLine';
import {Divider} from '~/common/_pb_components/atoms/Divider';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {AcctDrawerItem} from '~/MegaNav/AcctDrawerItem/AcctDrawerItem';
import {useMatchQuery} from '~/common/hooks/useMatchQuery';
import {largishAndUp} from '~sass/pb_styleguide/base/_exports.sass';
import {UserCallout} from './UserCallout';

export function AccountDetailsDrawer(props) {
  const parser = new DOMParser();
  const {matches: lgWindow} = useMatchQuery('(min-width: 80rem)', []);
  const {matches: largishScreen} = useMatchQuery(`(min-width: ${largishAndUp})`);
  const fullName = props.user.full_name || props.user.userName;
  const hasUserImage =
    (props.user.image_url && props.user.image_url !== 'None') ||
    (props.user.imgUrl && props.user.imgUrl !== 'None');
  const userImage = props.user.image_url || props.user.imgUrl;

  const [mwAcctSettingsOpen, setMwAcctSettingsOpen] = useState(false);

  /** Handle clicking Account Settings in the MW account details drawer */
  const onMwAcctSettingsClick = () => setMwAcctSettingsOpen(true);

  /** Handle clicking the back arrow in Account Settings in MW */
  const onMwAcctSettingsBackArrowClick = () => {
    setMwAcctSettingsOpen(false);
  };

  return (
    <div
      className={`acct-details-drawer ${props.open ? 'open' : ''} ${
        lgWindow ? `redesign-${props.navDesignVariationDesktop}` : 'full-height'
      }`}
      id="acctDetails"
    >
      {!lgWindow && (
        <>
          {mwAcctSettingsOpen ? (
            <div className="acct-details-drawer__header">
              <button
                className="unstyled-button"
                onClick={onMwAcctSettingsBackArrowClick}
                data-qa-id="closeAccountSettingsButton"
              >
                <PB_Arrow ratio={0.75} direction="left" />
              </button>
              <Typography as="h3" variant="header3" data-qa-id="accountSettingsHeaderText">
                Account settings
              </Typography>
            </div>
          ) : (
            <div className="acct-details-drawer__header">
              <button
                className="unstyled-button"
                onClick={props.onClose}
                tabIndex={props.open ? 0 : -1}
                data-qa-id="closeAccountSettingsButton"
              >
                <PB_CloseLine ratio={0.75} />
              </button>
              <div className="acct-details-drawer__user-details">
                {props.navDesignVariationMW === 'variant' ? (
                  <UserCallout name={fullName} className="acct-details-drawer__user-name" />
                ) : (
                  <span className="acct-details-drawer__user-name">
                    {
                      parser.parseFromString(`<!doctype html><body>${fullName}</body>`, 'text/html')
                        .body.textContent
                    }
                  </span>
                )}
                <div className="nav-avatar big">
                  {hasUserImage ? (
                    <img src={userImage} alt="Profile picture" />
                  ) : (
                    <Typography
                      as="p"
                      variant="paragraph2"
                      data-qa-id="accountSettingsUserAvatarInitials"
                    >
                      {props.user.initials}
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          )}
          <Divider marginTop="1rem" marginBottom="1rem" />
        </>
      )}
      <div className="acct-details-drawer__cta-group">
        {lgWindow || !mwAcctSettingsOpen ? (
          <>
            {lgWindow && ['B', 'C'].includes(props.navDesignVariationDesktop) ? (
              <UserCallout name={fullName} className="acct-details-drawer__user-name" />
            ) : null}
            <AcctDrawerItem
              href="/my-events"
              text="My events"
              icon={<PB_CalendarLine ratio={lgWindow ? 0.75 : 1} />}
              tabIndex={props.open ? 0 : -1}
              data-qa-id="myEventsAcctDrawerItem"
            />
            <AcctDrawerItem
              href="/gallery/favorites"
              text={`My favorites${
                window.favoritesNumber !== undefined ? ` (${window.favoritesNumber})` : ''
              }`}
              icon={<PB_FavoritesLine ratio={lgWindow ? 0.75 : 1} />}
              tabIndex={props.open ? 0 : -1}
              data-qa-id="myFavoritesAcctDrawerItem"
            />
            {largishScreen && (
              <AcctDrawerItem
                href="/profile/contacts"
                text="Address book"
                icon={<PB_GuestListLine ratio={lgWindow ? 0.75 : 1} />}
                tabIndex={props.open ? 0 : -1}
                data-qa-id="addressBookAcctDrawerItem"
              />
            )}
            <AcctDrawerItem
              icon={<PB_SettingsLine ratio={lgWindow ? 0.75 : 1} />}
              text="Account settings"
              type={lgWindow ? 'link' : 'button'}
              onClick={onMwAcctSettingsClick}
              href="/settings/account"
              tabIndex={props.open ? 0 : -1}
              data-qa-id="accountSettingsAcctDrawerItem"
            />
            <AcctDrawerItem
              icon={<PB_ClickOutLine ratio={lgWindow ? 0.75 : 1} />}
              text="Sign out"
              onClick={props.onLogout}
              type="button"
              tabIndex={props.open ? 0 : -1}
              data-qa-id="signOutAcctDrawerItem"
            />
          </>
        ) : (
          <>
            <AcctDrawerItem
              href="/profile/edit"
              text="Edit profile"
              icon={<PB_EditLine ratio={lgWindow ? 0.75 : 1} />}
              tabIndex={props.open ? 0 : -1}
              data-qa-id="editProfileAcctDrawerItem"
            />
            <AcctDrawerItem
              href="/profile"
              text="Account info"
              icon={<PB_AccountLine ratio={lgWindow ? 0.75 : 1} />}
              tabIndex={props.open ? 0 : -1}
              data-qa-id="accountInfoAcctDrawerItem"
            />
            <AcctDrawerItem
              href="/settings/blocks"
              text="Manage block list"
              icon={<PB_AlertLine ratio={lgWindow ? 0.75 : 1} />}
              tabIndex={props.open ? 0 : -1}
              data-qa-id="manageBlockListAcctDrawerItem"
            />
            <AcctDrawerItem
              href="/settings/data_privacy"
              text="Data privacy"
              icon={<PB_LockLine ratio={lgWindow ? 0.75 : 1} />}
              tabIndex={props.open ? 0 : -1}
              data-qa-id="dataPrivacyAcctDrawerItem"
            />
            <AcctDrawerItem
              href="/settings/pm_order_history"
              text="Your orders"
              icon={<PB_ActivityFeedLine ratio={lgWindow ? 0.75 : 1} />}
              tabIndex={props.open ? 0 : -1}
              data-qa-id="yourOrdersAcctDrawerItem"
            />
          </>
        )}
      </div>
    </div>
  );
}

AccountDetailsDrawer.propTypes = {
  open: PropTypes.bool,
  user: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onLogout: PropTypes.func,
  navDesignVariationDesktop: PropTypes.oneOf(['control', 'B', 'C']).isRequired,
  navDesignVariationMW: PropTypes.oneOf(['control', 'variant']).isRequired,
};
