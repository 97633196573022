import React from 'react';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export function PB_ActivityFeedLine(props) {
  const {color = charcoal, className = '', ratio = 1} = props;

  // default dimensions
  const width = 32;
  const height = 32;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      onClick={props.onClick}
    >
      <path
        d="M26.6667 29.3334H5.33333C4.97971 29.3334 4.64057 29.1929 4.39052 28.9428C4.14048 28.6928 4 28.3536 4 28V4.00002C4 3.6464 4.14048 3.30726 4.39052 3.05721C4.64057 2.80716 4.97971 2.66669 5.33333 2.66669H26.6667C27.0203 2.66669 27.3594 2.80716 27.6095 3.05721C27.8595 3.30726 28 3.6464 28 4.00002V28C28 28.3536 27.8595 28.6928 27.6095 28.9428C27.3594 29.1929 27.0203 29.3334 26.6667 29.3334ZM25.3333 26.6667V5.33335H6.66667V26.6667H25.3333ZM10.6667 9.33335H21.3333V12H10.6667V9.33335ZM10.6667 14.6667H21.3333V17.3334H10.6667V14.6667ZM10.6667 20H17.3333V22.6667H10.6667V20Z"
        fill={color}
      />
    </svg>
  );
}
