import PropTypes from 'prop-types';
import cx from 'classnames';
import {Button} from '~/common/_pb_components/atoms/Button';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {Overlay} from '~/common/_pb_components/atoms/Overlay';
import {Spinner} from '~/common/components/Loader/Spinner';

// Intended for small, single-action content, returns a popup with overlay
// in both desktop & mobile.
// Requires isOpen, onClose, as well as the following:

// Requires either children:
//    (will render children with X close button in upper right in an otherwise empty modal)
// OR: all the below
//  title: (string),
//  body: (string),
//  primaryAction: (func called on primary CTA),
//  modalId: (string identifier for this modal)
//  primaryText: (string for primary CTA text)

export const ResponsiveModal = ({
  isOpen,
  body = null,
  children = null,
  onClose,
  primaryAction = onClose,
  secondaryAction = onClose,
  primaryText = 'Submit',
  secondaryText = 'Cancel',
  showSpinner = false,
  actionText = 'One moment please...',
  showSecondary = true,
  modalId = 'modal',
  className = '',
  isModal = false,
  showHeader,
  showPrimaryButton,
  title = '',
  closeOnClickOutside,
}) => {
  const renderedTitle = showSpinner ? actionText : title || `${primaryText}?` || 'Are you sure?';

  const titleText = showHeader ? (showSpinner ? actionText : renderedTitle || null) : null;

  let renderedChildren = showSpinner ? <Spinner center /> : children;

  const getModalChildren = () => (
    <div className={className}>
      <div className="responsive-modal-body">
        <Typography variant="paragraph2">{body || ''}</Typography>
      </div>
      <div className="responsive-modal-button-row">
        {showSecondary && (
          <Button
            variant="secondary"
            size="large"
            onClick={secondaryAction}
            data-qa-id={`${modalId}-cancel`}
          >
            {secondaryText}
          </Button>
        )}
        {showPrimaryButton && (
          <Button
            variant="primary"
            size="large"
            onClick={primaryAction}
            data-qa-id={`${modalId}-submit`}
          >
            {primaryText}
          </Button>
        )}
      </div>
    </div>
  );

  if (!renderedChildren) renderedChildren = getModalChildren();

  return (
    <Overlay
      open={isOpen}
      isModal={isModal}
      onClose={(e) => onClose(e)}
      modalId={cx('responsive-modal', modalId)}
      title={titleText}
      showSpinner={showSpinner}
      closeOnClickOutside={closeOnClickOutside}
    >
      {renderedChildren}
    </Overlay>
  );
};

ResponsiveModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  title: PropTypes.string,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  modalId: PropTypes.string,
  primaryAction: PropTypes.func,
  primaryText: PropTypes.string,
  secondaryAction: PropTypes.func,
  secondaryText: PropTypes.string,
  actionText: PropTypes.string,
  showSpinner: PropTypes.bool,
  showHeader: PropTypes.bool,
  showPrimaryButton: PropTypes.bool,
  showSecondary: PropTypes.bool,
  className: PropTypes.string,
};

ResponsiveModal.defaultProps = {
  children: null,
  title: null,
  body: null,
  modalId: undefined,
  primaryAction: undefined,
  primaryText: 'Submit',
  secondaryAction: undefined,
  secondaryText: 'Cancel',
  actionText: 'One moment please...',
  showSpinner: false,
  showHeader: true,
  showPrimaryButton: true,
  showSecondary: true,
  className: undefined,
};
