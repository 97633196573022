import React from 'react';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export function PB_CalendarLine(props) {
  const {color = charcoal, className = '', ratio = 1} = props;

  // default dimensions
  const width = 32;
  const height = 32;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.6666 3.99998H28C28.3536 3.99998 28.6927 4.14046 28.9428 4.3905C29.1928 4.64055 29.3333 4.97969 29.3333 5.33331V26.6666C29.3333 27.0203 29.1928 27.3594 28.9428 27.6095C28.6927 27.8595 28.3536 28 28 28H3.99996C3.64634 28 3.3072 27.8595 3.05715 27.6095C2.8071 27.3594 2.66663 27.0203 2.66663 26.6666V5.33331C2.66663 4.97969 2.8071 4.64055 3.05715 4.3905C3.3072 4.14046 3.64634 3.99998 3.99996 3.99998H9.33329V1.33331H12V3.99998H20V1.33331H22.6666V3.99998ZM20 6.66665H12V9.33331H9.33329V6.66665H5.33329V12H26.6666V6.66665H22.6666V9.33331H20V6.66665ZM26.6666 14.6666H5.33329V25.3333H26.6666V14.6666Z"
        fill={color}
      />
    </svg>
  );
}
