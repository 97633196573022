import {str} from 'evite';

// matching validation logic in /typhoon/assets/js/common/utils.js
export const validateEmail = (email, allowEmpty) => {
  const MAX_EMAIL_LENGTH = 254;

  email = str(email).trim();
  email = email.replace(/^[\s(<{\[]+/, '');
  email = email.replace(/[\s)>}\]]+$/, '');

  if (!email && allowEmpty) {
    return {valid: true, value: ''};
  }

  if (!email && !allowEmpty) {
    return {valid: false, message: 'Email address is required'};
  }

  if (email.length > MAX_EMAIL_LENGTH) {
    return {
      valid: false,
      message: `Email address max length is ${MAX_EMAIL_LENGTH}`,
    };
  }

  // to match backEnd validation
  // Server enforced regex
  const serverRegex =
    /(^\s*[a-zA-Z0-9.\"!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9_-]+\.[a-zA-Z0-9-.]+\s*$)/gm;
  // Browser enforced regex
  const browserRegex =
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gm;
  const isValidFormat = browserRegex.exec(email) && serverRegex.exec(email);

  if (!isValidFormat) {
    return {valid: false, message: 'Invalid email address'};
  }

  return {valid: true, value: email};
};
