import React from 'react';
import PropTypes from 'prop-types';

export function Link({
  fullWidth,
  thickness,
  variant,
  as,
  className,
  disabled,
  target = '_self',
  ...props
}) {
  let buttonClass = '';

  if (as) {
    buttonClass =
      as === 'primary'
        ? 'button primary'
        : as === 'transparent'
          ? 'button transparent'
          : 'button secondary';
  }

  return (
    <a
      className={`${!as ? 'link' : ''} ${variant || 'large'} ${thickness || ''} ${
        buttonClass || ''
      } ${disabled ? 'disabled' : ''} ${fullWidth ? 'wide' : ''} ${className || ''} `}
      target={target}
      {...props}
    />
  );
}

Link.propTypes = {
  href: PropTypes.string, // TODO this should be required & onClick removed -- Button with variant 'link' or 'transparent' should be used if not redirecting to a url
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf(['small', 'large']),
  fullWidth: PropTypes.bool,
  thickness: PropTypes.oneOf(['normal', 'thin']),
  as: PropTypes.oneOf(['primary', 'secondary', 'transparent']), // if it should look like a button (or NOT like a link, if using 'transparent')
  'data-qa-id': PropTypes.string.isRequired,
  className: PropTypes.string,
  target: PropTypes.string,
  disabled: PropTypes.bool,
};
