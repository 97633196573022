import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './DesktopExpandedCategoryView.sass';
import {getCategoryHref} from '../utils';
import {Link} from '~/common/_pb_components/atoms/Link';

export function DesktopExpandedCategoryView(props) {
  const {type, category_id: id} = props;
  const DEFAULT_NUM_TO_SHOW = ['B', 'C'].includes(props.navDesignVariationDesktop) ? 15 : 4;
  const [seeMore, setSeeMore] = useState(false);

  return (
    <div key={props.name} className={`subcategory redesign-${props.navDesignVariationDesktop}`}>
      {props.visible && (
        <Link
          className={`paragraph2 subcategory-inner-list-item ${
            props.href ? 'subcategory-name-link' : 'subcategory-name'
          }`}
          data-qa-id={props['data-qa-id']}
          href={props.href}
        >
          {props.display_name_prefix ? (
            <>
              {props.display_name_prefix} <br />
            </>
          ) : null}
          {props.display_name}
        </Link>
      )}

      {props.categories.slice(0, DEFAULT_NUM_TO_SHOW).map(({name, href, display_name}) => (
        <a
          key={name}
          href={getCategoryHref({name, href, id, type})}
          className="paragraph3 subcategory-inner-list-item"
          data-qa-id={`${name}_subcategoryLink`}
          onClick={(e) => {
            const {category_names: cn} = props;
            const {inVariation: is} = evite.experiments;

            if (is('dyo_uyo_landing', 'landing') && cn.includes('design_your_own')) {
              e.preventDefault();
              window.location.href = '/upload-your-own';
            }
          }}
        >
          {display_name}
        </a>
      ))}
      {props.categories.length > DEFAULT_NUM_TO_SHOW &&
        (seeMore ? (
          props.categories.slice(DEFAULT_NUM_TO_SHOW).map((c) => (
            <a
              key={c.name}
              href={getCategoryHref(c)}
              className="paragraph3 subcategory-inner-list-item"
              data-qa-id={`${c.name}_subCategoryLink`}
            >
              {c.display_name}
            </a>
          ))
        ) : (
          <button
            onClick={() => setSeeMore(true)}
            className="unstyled-button paragraph3 see-more-link"
            data-qa-id={`${name}_subCategory_SeeMoreButton`}
          >
            {props.categories.length - DEFAULT_NUM_TO_SHOW} More
          </button>
        ))}
    </div>
  );
}

DesktopExpandedCategoryView.propTypes = {
  category_id: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object),
  category_names: PropTypes.arrayOf(PropTypes.string),
  display_name: PropTypes.string,
  display_order_num: PropTypes.number,
  name: PropTypes.string,
  sub_groups: PropTypes.number,
  visible: PropTypes.bool,
  type: PropTypes.oneOf(['invitation', 'card']).isRequired,
  'data-qa-id': PropTypes.string.isRequired,
  navDesignVariationDesktop: PropTypes.oneOf(['control', 'B', 'C']).isRequired,
  href: PropTypes.string,
};
