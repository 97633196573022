import React from 'react';
import PropTypes from 'prop-types';

import './AccountPreview.sass';
import {Typography} from '~/common/_pb_components/atoms/Typography';
import {Tooltip} from '~/common/_pb_components/molecules/Tooltip';
import {UserCallout} from '../AccountDetailsDrawer/UserCallout';

export const AccountPreview = ({user, onClick, navDesignVariation}) => {
  const displayName = user.first_name || user.firstName;
  const hasUserImage =
    (user.image_url && user.image_url !== 'None') || (user.imgUrl && user.imgUrl !== 'None');
  const userImage = user.image_url || user.imgUrl;

  return (
    <>
      <button
        className="unstyled-button acct-details"
        onClick={onClick}
        data-qa-id="openAccountDetailsButton"
        type="button"
      >
        {navDesignVariation === 'control' ? (
          <UserCallout name={displayName} className="nav-user-name" dataQaId="userDisplayName" />
        ) : null}

        <div data-for="myEventsMovedTooltip">
          <div className="nav-avatar">
            {hasUserImage ? (
              <img src={userImage} alt="Profile" />
            ) : (
              <Typography as="p" variant="paragraph4" data-qa-id="userAvatarInitials">
                {user.initials}
              </Typography>
            )}
          </div>
        </div>
      </button>
      <Tooltip position="bottom" id="myEventsMovedTooltip" data-qa-id="myEventsMovedTooltip">
        Tap your profile & go to &quot;My events&quot; to see all your events and eCards.
      </Tooltip>
    </>
  );
};

AccountPreview.propTypes = {
  user: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  onClick: PropTypes.func.isRequired,
  navDesignVariation: PropTypes.oneOf(['control', 'variant', 'B', 'C']).isRequired,
};
